code {
  background: #ececec;
  border-radius: 2px;
  color: black;
}

body {
  font-family: "Inter", "Helvetica", "Arial", sans-serif !important;
  font-size: 14px !important;
}
