code {
  color: #000;
  background: #ececec;
  border-radius: 2px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: Inter, Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

a {
  text-decoration: none;
}
/*# sourceMappingURL=indexAdmin.css.map */
